<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import {Tooltip} from "element-ui";

export default {
  components: {
    [Tooltip.name]: Tooltip,
  },
  props: {
    stats:  {
      description: 'An a account'
    },
    type: {
      default: 'card',
      description: 'card ou table'
    }
  },
  data() {
    return {

    }
  },
  methods: {
    returnStatus(stats) {
      if (stats.account_status === 'failed') {
        return 'failed'
      } else if (stats.account_status === 'validating' || stats.account_status === 'checkingip' || stats.unvalidated) {
        return 'validating'
      } else if (stats.nofollower_failed_login && stats.strategy_id === undefined) {
        return 'disconnected'
      } else if (stats.account_status === 'validated' && stats.strategy_id === undefined && !stats.nofollower_failed_login){
        return 'validated'
      } else if (stats.account_status === 'validated' && stats.strategy_id !== undefined) {
        return 'hasStrategy'
      }
    }
  }
}
</script>

<template>
  <div v-if="type === 'card'">
    <el-tooltip :content="`${$t('helper_failedvalidation')}`"
       :open-delay="100"
        placement="top"
        popper-class="adjust-width"
        v-if="returnStatus(stats) === 'failed'">
        <p-button type="danger" round >
          <i class="fa fa-times-circle"></i>
          {{ $t('failedvalidationtxt') }}
        </p-button>
      </el-tooltip>

    <el-tooltip :content="`${$t('helper_pendingvalidation')}`"
      :open-delay="100"
      placement="top"
      popper-class="adjust-width"
      v-else-if="returnStatus(stats) === 'validating'">
      <p-button type="danger" round >
        <i class="fa fa-spinner fa-spin"></i>
        {{ $t('pendingvalidationtxt') }}
      </p-button>
    </el-tooltip>

    <el-tooltip :content="`${$t('helper_failedvalidation')}`"
      :open-delay="100"
      placement="top"
      popper-class="adjust-width"
      v-else-if="returnStatus(stats) === 'disconnected'">
        <p-button type="danger" round >
          <i class="fa fa-plug"></i>
          {{ $t("disconnected") }}
        </p-button>
    </el-tooltip>

    <el-tooltip :content="`${$t('helper_validated')}`"
      :open-delay="100"
      placement="top"
      popper-class="adjust-width"
      v-else-if="returnStatus(stats) === 'validated'" >
        <p-button type="success" round >
          <i class="fa fa-check-circle"></i>
          {{ $t("validated") }}
        </p-button>
    </el-tooltip>

    <el-tooltip :content="stats.strategy_name"
      :open-delay="100"
      placement="top"
      v-else-if="returnStatus(stats) === 'hasStrategy'">
        <p-button type="info" round >
          <i class="nc-icon nc-sound-wave"></i>
          {{ $truncate(stats.strategy_name) }}
        </p-button>
      </el-tooltip>

    <el-tooltip :content="`${$t('mastertxt')}`"
      :open-delay="100"
      placement="top"
      v-if="returnStatus(stats) === 'hasStrategy' &&
      stats.account_type === 'master'">
        <p-button type="warning" icon round >
          M
        </p-button>
    </el-tooltip>

    <el-tooltip :content="`${$t('followertxt')}`"
      :open-delay="100"
      placement="top"
      v-if="returnStatus(stats) === 'hasStrategy' &&
            stats.account_type !== 'master'">
        <p-button type="info" icon round >
          F
        </p-button>
      </el-tooltip>
  </div>
  <div v-else>
    <el-tooltip :content="`${$t('helper_failedvalidation')}`"
      :open-delay="100"
      placement="top"
      popper-class="adjust-width"
      v-if="returnStatus(stats) === 'failed'"
      >
        <span class="text-danger">
          <i class="fa fa-times-circle"></i>
          {{ $t("failedvalidationtxt") }}
        </span>
    </el-tooltip>

    <el-tooltip :content="`${$t('helper_pendingvalidation')}`"
        :open-delay="100"
        placement="top"
        popper-class="adjust-width"
        v-else-if="returnStatus(stats) === 'validating'"
    >
        <span class="text-danger">
          <i class="fa fa-spinner fa-spin"></i>
          {{ $t("pendingvalidationtxt") }}
        </span>
    </el-tooltip>

    <el-tooltip :content="`${$t('helper_pendingvalidation')}`"
        :open-delay="100"
        placement="top"
        popper-class="adjust-width"
        v-else-if="returnStatus(stats) === 'pending'">
        <span class="text-danger">
          {{ $t("pendingvalidationtxt") }}
        </span>
    </el-tooltip>

    <el-tooltip :content="`${$t('helper_pendingvalidation')}`"
        :open-delay="100"
        placement="top"
        popper-class="adjust-width"
        v-else-if="returnStatus(stats) === 'missing'">
        <span class="text-danger">
          {{ $t("missing") }}
        </span>
    </el-tooltip>

    <el-tooltip :content="`${$t('helper_validated')}`"
        :open-delay="100"
        placement="top"
        popper-class="adjust-width"
        v-else-if="returnStatus(stats) === 'validated'">
        <span class="text-success">
          <i class="fa fa-check-circle"></i>
          {{ $t("validated") }}
        </span>
    </el-tooltip>

    <el-tooltip :content="stats.strategy_name"
        :open-delay="100"
        placement="top"
        v-else-if="returnStatus(stats) === 'hasStrategy'">
        <span class="text-info">
          <i class="nc-icon nc-sound-wave"></i>
          {{
            $truncate(stats.strategy_name, 10)
          }}
        </span>
    </el-tooltip>

    <el-tooltip :content="`${$t('mastertxt')}`"
        :open-delay="100"
        placement="top"
        v-if="returnStatus(stats) === 'hasStrategy' && stats.account_type === 'master'">
        <span class="p-badge badge-warning badge-pill">
          M
        </span>
    </el-tooltip>

    <el-tooltip :content="`${$t('followertxt')}`"
      :open-delay="100"
      placement="top"
      v-if="returnStatus(stats) === 'hasStrategy' && stats.account_type !== 'master'">>
      <span class="p-badge badge-info badge-pill">
        F
      </span>
      </el-tooltip>
  </div>
</template>

<style scoped>

</style>
