<template>
  <modal
    :show.sync="syncAccounts"
    :required="true"
    headerClasses="justify-content-center"
    modalClasses="modal-lg"
    style="padding-top: 0;">
    <template slot="header">
      <h4 class="title title-up">{{ $t('accountstosync') }}</h4>
    </template>
    <el-row class="container" :gutter="20" align="left" style="max-height: 400px; overflow-x: hidden;">
      <div v-if="accounts.length > 0" class="row">
        <div class="col-md-6" v-for="acc in accounts">
          <div id="syncWithStrategy" class="broker-logo">
            <p-checkbox class="checkbox" @input="addAccountToSync(acc)" v-model="acc.selected">
              <el-avatar class="avatar" :size="60" :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${acc.broker_logo}.png`"/>
            </p-checkbox>
          </div>
          <div>
            <span v-if="acc.account_nickname === acc.account_number || !acc.account_nickname" class="font-weight-normal" style="font-size: 20px;">
                {{ acc.account_number }}
            </span>
            <span v-else class="font-weight-normal" style="font-size: 20px;">
                {{ acc.account_nickname }}  <small class="category">({{acc.account_number}})</small>
            </span>

            <br>
            <span class="text-muted" v-if="acc.info_balance">
              <b>{{ txt.balance }}:</b>
              <b class="text-success">
                {{ acc.info_balance.toFixed(2) }}
              </b>
            </span>
            <span class="text-muted" v-if="acc.info_equity">
              <b>{{ txt.equity }}:</b>
              <b :class="acc.info_equity < acc.info_balance ? 'text-danger' : 'text-success'">
                {{ acc.info_equity.toFixed(2) }}
              </b>
            </span>
            <br>
            <p></p>
            <hr>
          </div>
        </div>
      </div>
      <h4 v-else class="title-up text-center">
        {{_capitalizeFirstLetter($t("accountstosync") + " " + $t("ERROR_NOT_AVAILABLE"))}}
      </h4>
    </el-row>
    <template slot="footer">
      <p-button type="danger" @click="_closeModal" link>
        {{ txt.cancel }}
      </p-button>
      <p-button type="success" @click="_syncAccounts" link :disabled="accountsToSync.length === 0">
        {{ txt.syncwithstrategy }}
      </p-button>
    </template>
  </modal>
</template>
<script>

import Vue from 'vue';
import {Row} from 'element-ui'
import {mapActions, mapState} from "vuex";
import Modal from "@/components/UIComponents/Modal.vue";
import swal from "sweetalert2";

Vue.use(Row)

export default {
  name: 'syncAccountsWithStrategy',
  components: {Modal},
  data() {
    return {
      accountsToSync: [],
      txt: {
        cancel: this.$t('cancel'),
        syncwithstrategy: this.$t('sync_with_strategy'),
        balance: this.$t('balance'),
        equity: this.$t('equity'),
      }
    }
  },
  computed: {
     ...mapState('marketplaceFollowStrategy', [
        'accounts',
        'syncAccounts',
    ]),
  },
  methods: {
    ...mapActions("marketplaceFollowStrategy", ['closeSyncAccounts']),
    addAccountToSync(acc) {
      if (!this.accountsToSync.find(el => el.account_id === acc.account_id)) {
        this.accountsToSync.push(acc);
      } else {
        let idx = this.accountsToSync.findIndex(el => el.account_id === acc.account_id)
        this.accountsToSync.splice(idx, 1);
      }
    },
    _closeModal() {
        this.accountsToSync = [];
        for (let i = 0; i < this.accounts.length; i++) {
          this.accounts[i].selected = false;
        }
        this.closeSyncAccounts();
    },
    _syncAccounts() {
      swal({
            title: this.$t('attentiontxt'),
            text: this.$t('sync_strategy_info'),
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
          }).then(
            value => {

              const syncAccountsSuccess = (response) => {
                if (response.success) {
                  this.$toast.success(this.$t('syncwithstrategytxt'));
                  this.closeSyncAccounts();
                }
              }

              const failop = (error) => {
                this.$toast.error(this.$t('ERROR_BADREQUEST'));
                this.closeSyncAccounts();
              }

              let accountsToSync = []

              for(let i = 0; i < this.accountsToSync.length; i++) {
                let acc = {
                  strategy_id: this.accountsToSync[i].strategy_id,
                  account_id: this.accountsToSync[i].account_id,
                  mat_server: this.accountsToSync[i].mat_servers
                }
                accountsToSync.push(acc);
              }
              this.$addToSyncTrades({
                data: accountsToSync
              }).then(syncAccountsSuccess, failop)

            },
            dismiss => {

            }
          ).catch(swal.noop);
    },
    _capitalizeFirstLetter(text) {
        if (!text) return text;
        text = text.toLowerCase()
        return text.charAt(0).toUpperCase() + text.slice(1);
    },
  }
}
</script>

<style scoped lang="scss">

#syncWithStrategy::v-deep {
  .form-check .form-check-sign {
    &:before, &:after {
      top: 1rem;
    }
  }
}

.broker-logo {
  float: left;
  position: relative;
  padding: 0 10px 10px 3px;
}
.broker-logo img {
  border-radius: 50%;
  width: 50px;
  border: 1px solid silver;
}
.broker-logo p-checkbox{
  padding: 10px 10px 10px 3px;
}

span {
  color: black;
}

</style>
