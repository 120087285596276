<template>
  <modal :show.sync="showRemoveAccFromStrategyModal" :required="true" headerClasses="justify-content-center" modalClasses="modal-lg">
    <template slot="header">
      <h4 v-if="strategy && strategy.strategy_name"> Accounts following <b>{{strategy.strategy_name}}</b> strategy </h4>
    </template>
    <template slot="default" v-if="!allDone">
      <div id="removeAccountFromStrategy"  style="max-height: 400px; overflow-y: auto; overflow-x: hidden;">
        <div class="row">
         <div class="col-md-12 text-center" v-if="inprogress">
            <h2> {{ txt.inprogressMessage }} </h2>
            <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
          </div>
          <div class="col-md-6" v-for="acc in accounts" v-if="!inprogress">
            <div class="row">
              <div class="col-md-3" style="padding-right: 0px">
                <div class="broker-logo" style="margin-top: 20%; padding-right: 0px">
                  <p-checkbox @input="selectAccountToRemove(acc)">
                    <img
                      style="margin-top: unset;"
                      :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${acc.broker_logo}.png`">
                  </p-checkbox>
                </div>
              </div>
              <div class="col-md-9" style="padding-left: 0px">
                <div>
              <span v-if="acc.account_nickname == acc.account_number || !acc.account_nickname"
                    class="font-weight-normal" style="color: black; font-size: 20px">
                  {{ acc.account_number }}
              </span>
              <span v-else class="font-weight-normal" style="color: black; font-size: 20px">
                  {{ acc.account_nickname }}  <small class="category">({{ acc.account_number }})</small>
              </span>
                <br>
                  <span class="text-muted" v-if="acc.first_name && acc.last_name">
                    <b>
                      {{ acc.first_name }} {{ acc.last_name }}
                    </b>
                    <br>
                  </span>
                <span>
                  <b v-if="acc.userid">
                    {{ acc.userid }}
                  </b>
                  <b v-else>
                    {{ acc.account_userid }}
                  </b>
                </span>
                <br>
                  <span class="text-muted" v-if="acc.account_status && acc.info_balance">
                <b>{{ txt.balance }}:</b>
                <b class="text-success">
                  {{ acc.info_balance.toFixed(2) }}
                </b>
              </span>
                  <span class="text-muted" v-if="acc.account_status && acc.info_equity">
                <b>{{ txt.equity }}:</b>
                <b :class="acc.info_equity < acc.info_balance ? 'text-danger' : 'text-success'">
                  {{ acc.info_equity.toFixed(2) }}
                </b>
              </span>
              <!--    <span class="text-muted" v-if="acc.info_balance">
                <b>{{ txt.balance }}:</b>
                <b class="text-success">
                  {{ acc.info_balance.toFixed(2) }}
                </b>
              </span>
                  <span class="text-muted" v-if="acc.info_equity">
                <b>{{ txt.equity }}:</b>
                <b :class="acc.info_equity < acc.info_balance ? 'text-danger' : 'text-success'">
                  {{ acc.info_equity.toFixed(2) }}
                </b>
              </span>-->
                  <br>
                  <p></p>
                  <hr>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
<!--      <div class="col-md-12 text-center" v-if="!inprogress">-->
<!--        <br> -->
<!--        <p-checkbox v-model="allAccounts" round icon link v-on:input="selectAllAccounts()">-->
<!--          <span style="top: 1rem;">{{ $t('checkalltxt') }} </span>-->
<!--        </p-checkbox>-->
<!--        {{selectedAccounts}}-->
<!--      </div>-->
    </template>
    <template slot="default" v-if="allDone">
       <div class="col-md-12 text-center" >
          <div class="col-md-12 text-center" style="margin-bottom: 20px">
            <i class="fa fa-thumbs-up fa-huge text-success"></i>
          </div>
          <h6> {{ $t('accountremoved') }} </h6>
       </div>
    </template>
    <template slot="footer">
      <p-button @click="resetAndClose" type="success" link v-if="allDone">
        {{ txt.done }}
      </p-button>
      <p-button type="success" @click="confirmRemove" link v-if="!allDone">
        {{ txt.done }}
      </p-button>
      <p-button @click="resetAndClose" link v-if="!allDone" >
        {{ txt.cancel }}
      </p-button>

    </template>
  </modal>
</template>

<script>

import {mapActions, mapState} from "vuex";
import Modal from 'src/components/UIComponents/Modal';
import {Tooltip,} from 'element-ui';
import swal from "sweetalert2";
import Constants from "@/assets/constants";

const rules = Constants.methods.getRules();

export default {
  name: "RemoveAccountsFromStrategy",
  components: { Modal, [Tooltip.name]: Tooltip },
  computed: {
    ...mapState('removeAccountsFromStrategy', ['showRemoveAccFromStrategyModal','setAccountsAvailableToRemove' ,'accounts', 'strategy'])
  },
  props: {
    openModal: {
      type: Function,
      description: 'Open modal'
    }
  },
  data() {
    return {
      txt: {
        parameterstxt: this.$t('changelogpendingparamchange'),
        modalTitle: this.$t('accountstofollow'),
        byfollowingterms: this.$t('byfollowingterms'),
        pricingterms: this.$t('pricingterms'),
        addaccount: this.$t('addaccount'),
        cancel: this.$t('cancel'),
        linktxt: this.$t('linktxt'),
        iagreetxt: this.$t('iagreetxt'),
        balance: this.$t('balance'),
        notavailable: this.$t('notavailable'),
        equity: this.$t('equity'),
        inprogressMessage: this.$t('pleasewait'),
        done: this.$t('done'),
        somethingwentwrong: this.$t('somethingwentwrong'),
        successFollowed: this.$t('SUCCESS_STR_FOLLOW'),
        next: this.$t('next'),
        back: this.$t('back'),
        notAvailableForStrategy: this.$t('noAccountsAvailableForStrategy')
      },
      inprogress: false,
      allAccounts: false,
      allDone: false,
      selectedAccounts:[],
      idAdmin: rules.isAdmin,
    }
  },
  methods: {
    ...mapActions('removeAccountsFromStrategy', ['clean']),
    resetAndClose() {
      this.inprogress = false
      this.allDone = false
      this.selectedAccounts = []
      this.clean()
    },
    confirmRemove() {
      if (this.selectedAccounts.length == 0 ) {
        this.$toast.warning(this.$t('mustSelectAccount'))
      } else {
        this.inprogress = true

        let opSuccess = resp => {
          this.inprogress = false
          this.allDone = true
          this.$emit('on-finish', this.strategy)
        }
        let opFail = resp => {
          this.inprogress = false
          console.log(resp)
          swal(this.$t('attentiontxt'), this.$t('rdpdownmsg'), "warning").then(this.resetAndClose)
        }

        let param = {
          strat_id: this.strategy.strategy_id,
          accounts: this.selectedAccounts
        }
        this.$bulkRemoveAccountsFromStrategy(param).then(opSuccess, opFail)
      }
    },
    selectAccountToRemove(acc) {
      if(this.selectedAccounts.some( a => a === acc.account_id )) {
        this.selectedAccounts = this.selectedAccounts.filter( a => a !== acc.account_id)
      } else  {
        this.selectedAccounts.push(acc.account_id)
      }
    },
    selectAllAccounts() {
      this.accounts.forEach( el => this.selectAccountToRemove(el.account_id) )
    }
  },
  watch: {
    showRemoveAccFromStrategyModal(val) {
      if (val) {

      } else {
        this.modalinprogress = false
        this.selectedAccounts = []
      }
    }
  }
}
</script>

<style scoped lang="scss">
#removeAccountFromStrategy::v-deep {
  .form-check .form-check-sign {
    &:before, &:after {
      top: 1rem;
    }
  }
}
.broker-logo {
  float: left;
  position: relative;
  padding: 0 10px 10px 3px;
}
.broker-logo img {
  border-radius: 50%;
  width: 50px;
  border: 1px solid silver;
}
.broker-logo p-checkbox{
  padding: 10px 10px 10px 3px;
}
</style>
