<template>
  <modal
    required
    :show.sync="isShow"
    :required="true"
    headerClasses="justify-content-center"
  >
    <template slot="header">
      <h4 v-if="newAccount" slot="header" class="title title-up">
      {{ $t("newaccounttitle") }}
      </h4>
      <h4 v-else slot="header" class="title title-up">
        {{ $t("editaccounttxt") }}
      </h4>
    </template>
    <template v-if="brokerList.length == 0" slot="body">
      <div class="text-center">
        <h2>{{ $t('loadingtxt') }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>
    </template>
    <template v-else slot="body">
      <div class="col-md-12 mt-2 mb-2">
<!--        Broker-->
        <div class="row">
          <label> {{ $t("choosebroker") }} </label>
          <select
            v-model="acc.account_broker"
            :disabled="disableField || acc.unvalidated"
            class="form-control"
            @change="onChangeBroker"
          >
            <option disabled selected value="undefined">
              {{ $t("choosebroker") }}
            </option>
            <option
              v-for="(broker, index) in brokerList"
              v-if="newAccount"
              :key="index"
              :value="broker.broker"
            >
              {{ broker.broker }}
            </option>
            <option v-else :value="acc.account_broker" selected >
              {{ acc.account_broker }}
            </option>
          </select>
          <fg-input
            v-model="acc.other"
            class="mt-3"
            addon-left-icon="fa fa-folder-o"
            type="text"
            :placeholder="helper_acc_broker"
            v-if="acc.account_broker === 'Other'"
          ></fg-input>
        </div>
<!--        Platform-->
        <div class="row mt-2">
          <label>{{ $t("platformsavailable") }}</label>
            <select
              class="form-control"
              :disabled="disableField || acc.unvalidated"
              @change="onChangePlatform"
              v-model="acc.account_platform"
            >
              <option disabled selected value="undefined">
                {{ $t("platformsavailable") }}
              </option>
              <option v-for="item in platform_options"
                :key="item.label"
                :value="item.value">
                {{item.label}}
              </option>
              <option v-if="!newAccount" value="mt4">MT4</option>
              <option v-if="!newAccount" value="mt5">MT5</option>

            </select>
        </div>
<!--        Server-->
        <div v-if="newAccount" class="row mt-2">
          <label>{{ helper_acc_server }}</label>
          <select
            class="form-control"
            type="text"
            v-model="acc.account_server"
            :disabled="!acc.account_platform"
            v-if="acc.account_broker !== 'Other'"
          >
            <option selected disabled value="undefined">
              {{ helper_server_txt }}
            </option>
            <option v-if="!server_not_available"
              v-for="(option, index) in server_options"
              :value="option.server"
              :key="index"
            >
              {{option.server}}
            </option>
          </select>
          <fg-input
              :placeholder="helper_acc_server"
              type="text"
              replace="[^0-9a-zA-Z- _]"
              addon-left-icon="fa fa-server"
              v-if="acc.account_server === 'Other'"
              v-model="otherServer"
              v-on:change="removeSpace()"
          ></fg-input>
          <fg-input
              :placeholder="helper_acc_server"
              type="text"
              replace="[^0-9a-zA-Z-_]"
              addon-left-icon="fa fa-server"
              v-if="acc.account_broker === 'Other'"
              v-model="acc.other_server"
            ></fg-input>
        </div>
        <div v-if="!newAccount" class="row mt-2">
          <label>{{ helper_acc_server }}</label>
          <fg-input
              :placeholder="helper_acc_server"
              type="text"
              replace="[^0-9a-zA-Z- _]"
              addon-left-icon="fa fa-server"
              disabled="true"
              v-if="!newAccount"
              v-model="acc.account_server"
              v-on:change="removeSpace()"
          ></fg-input>
        </div>
<!--        Account Number-->
        <div class="row mt-2">
          <label>{{ helper_acc_entry }}</label>
          <fg-input
            v-model="acc.account_number"
            replace="[^0-9]"
            addon-left-icon="fa fa-archive"
            type="text"
            :disabled="disableField || acc.unvalidated"
            :placeholder="helper_acc_entry"
          ></fg-input>
        </div>
<!--        Password-->
        <div class="row mt-2">
          <label> {{ helper_acc_pwd }} </label>
          <fg-input
            v-model="acc.account_password"
            addon-left-icon="fa fa-key"
            :disabled="disableField"
            :placeholder="helper_acc_pwd"
          ></fg-input>
        </div>
<!--        Nick-->
        <div class="row mt-2">
          <label>{{ nickname_account }}</label>
          <fg-input
            replace="[^0-9a-zA-Z-_]"
            type="text"
            addon-left-icon="fa fa-id-card"
            v-model="acc.account_nickname"
          ></fg-input>
      </div>

        <div class="row text-center mt-2" >
          {{ $t("centsaccountinfo") }}
        </div>

        <div v-if="adminUsr" class="row text-center mt-4">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <p-checkbox v-model="acc.account_cent">{{ $t("centsaccount") }}?</p-checkbox>
          </div>
          <div class="col-md-3"></div>
        </div>

      </div>
    </template>
    <template slot="footer">
      <div class="py-3 mx-auto" v-if="modalInProgress">
        <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda"></i>
      </div>
      <div class="left-side" v-if="modalInProgress === false">
        <p-button
          type="success"
          @click.prevent="validate"
          link
          v-if="newAccount"
        >
          {{ $t("addaccount") }}
        </p-button>
        <p-button
          type="success"
          @click.prevent="validate"
          link
          v-if="!newAccount"
        >
          {{ $t("upentrybtn") }}
        </p-button>
      </div>
      <div class="divider" v-if="modalInProgress === false"></div>
      <div class="right-side" v-if="modalInProgress === false">
        <p-button type="danger" v-on:click="closeModal" link
          >{{ $t("cancel") }}
        </p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/UIComponents/Modal.vue";
import Constants from "@/assets/constants";

export default {
  name: "UpdateCredentials",
  components: { Modal },
  data() {
    return {
      otherServer: "",
      helper_acc_pwd: this.$t("helper_accpwd"),
      helper_acc_entry: this.$t("helper_accentry"),
      helper_acc_broker: this.$t("helper_accbroker"),
      helper_acc_server: this.$t("helper_accserver"),
      nickname_account: this.$t("nickname_account"),
      not_available_txt: this.$t('ERROR_NOT_AVAILABLE'),
      platform_options: [],
      server_options: [],
      servers: [],
      server_not_available: false,
      modalInProgress: false,
    };
  },
  props: {
    isShow: { type: Boolean, default: false },
    newAccount: { type: Boolean, default: false },
    adminUsr: { type: Boolean, default: false },
    acc: { type: Object, default: () => {} },
    brokerList: { type: Array, default: () => [] },
    autoResults: { type: Array, default: () => [] },
  },
  computed: {
    disableField() {
      return this.acc.account_status === "validated";
    },
    helper_server_txt() {
      return this.server_not_available ? this.not_available_txt : this.helper_acc_server
    }
  },
  methods: {
    multiAddAccount() {
      this.$emit("add:multi");
    },
    openAccountPage() {
      this.$emit("open:account-page");
    },
    onChangeBroker() {
      this.resetFilds()
      this.servers = []
      let success = resp => {
        this.servers = resp.data
        this.loadPlatform()
        this.onChangePlatform()
      }
      let fail = resp => {
        console.log(resp)
      }

      if(this.server_options.length === 0) {
        this.$getServerByBroker(this.acc.account_broker).then(success, fail)
      } else {
        this.onChangePlatform()
      }

    },
    onChangePlatform() {
      this.server_options = this.servers.filter( el => el.platform === this.acc.account_platform)
      if (this.server_options.length === 1) {
        this.acc.account_server = this.server_options[0].server
      } else {
        this.acc.account_server = ''
      }
      if (this.server_options.length === 0 ) {
        this.server_not_available = true;
      } else {
        this.server_not_available = false;
      }
    },
    resetFilds(){
      this.acc.account_platform = ''
      this.acc.account_server = ''
      this.server_options = []
    },
    loadPlatform(){
      let broker_row = this.brokerList.find( el => el.broker === this.acc.account_broker)
      if (broker_row.platform !== 'both' && this.acc.account_broker !== 'Other') {
        this.platform_options = [
          {
            label: broker_row.platform.toUpperCase(),
            value: broker_row.platform
          }
        ]
        this.acc.account_platform = broker_row.platform
      } else {
        this.platform_options = [
          {
            label: 'MT4',
            value: 'mt4',
          },
          {
            label: 'MT5',
            value: 'mt5'
          }
        ]
      }
    },
    closeModal() {
      this.$emit("modal:close");
    },
    failacc(error) {
      this.modal_in_progress = false;
      this.$toast.warning(error);
    },
    validate() {
      this.modalInProgress = true;

      if (this.acc.account_broker === 'Other') {
        this.acc.account_server = this.acc.other_server;
      }

      if (
          !this.acc.account_number ||
          !this.acc.account_broker ||
          (!this.acc.account_server && !this.acc.otherServer) ||
          !this.acc.account_password
      ) {
        this.modalInProgress = false;
        this.failacc(this.$t("populatefieldbelow"));
        return;
      }
      if (this.acc.account_server === "Other" && !this.otherServer) {
        this.modalInProgress = false;
        this.failacc(this.$t("populatefieldbelow"));
        return;
      }

      if (this.acc.account_server === "Other") {
        this.acc.account_server = this.otherServer;
      }
      this.modalInProgress = false
      this.$emit("validate");
    },
    removeSpace() {
      this.otherServer = this.otherServer.trim();
    },
    isMobile() {
      return Constants.methods.isMobile();
    },
  },
};
</script>

<style scoped></style>
