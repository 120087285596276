<template>
  <modal
    :show.sync="showAccModal"
    :required="true"
    headerClasses="justify-content-center"
    modalClasses="modal-lg">
    <template slot="header">
      <h4 class="title title-up">{{ txt.modalTitle }}</h4>
    </template>

    <template slot="default" v-if="selectAccountsTemplate">
      <div style="max-height: 350px; overflow-y: auto; overflow-x: hidden;">
        <div class="row">
          <div class="col-md-12 text-center" v-if="inprogress">
            <h2> {{ txt.inprogressMessage }} </h2>
            <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
          </div>
          <div class="col-md-6" v-if="!inprogress" v-for="acc in acc_list">
            <div id="accToFollow" class="broker-logo" v-if="!bulkFollowDone" >
              <p-checkbox @input="addAccountToFollow(acc)" v-model="acc.selected">
                <img
                  style="margin-top: unset;"
                  :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${acc.broker_logo}.png`">
              </p-checkbox>
            </div>
            <div>
              <span v-if="acc.nickname == acc.account || !acc.nickname"
                    class="font-weight-normal" style="color: black; font-size: 20px">
                  {{ acc.account }}
              </span>
              <span v-else class="font-weight-normal" style="color: black; font-size: 20px">
                  {{ acc.nickname }}  <small class="category">({{acc.account}})</small>
              </span>
              <br>
              <span class="text-muted" v-if="acc.balance">
                <b>{{ txt.balance }}:</b>
                <b class="text-success">
                  {{ acc.balance.toFixed(2) }}
                </b>
              </span>
              <span class="text-muted" v-if="acc.equity">
                <b>{{ txt.equity }}:</b>
                <b :class="acc.equity < acc.balance ? 'text-danger' : 'text-success'">
                  {{ acc.equity.toFixed(2) }}
                </b>
              </span>
              <br>
              <p></p>
              <hr>
            </div>

          </div>
        </div>
      </div>
    </template>

    <template slot="footer" v-if="selectAccountsTemplate">
      <div class="py-3 mx-auto" v-if="modalinprogress">
        <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
      </div>
      <div class="left-side" v-if="modalinprogress == false">
        <p-button type="success" @click.prevent="goNext" link :disabled="selectedAccounts.length == 0">
          {{ txt.next }}
        </p-button>
      </div>
      <div class="divider" v-if="modalinprogress == false"></div>
      <div class="right-side" v-if="modalinprogress == false">
        <p-button type="danger" @click="clean" link>
          {{ txt.cancel }}
        </p-button>
      </div>
    </template>

    <template slot="default" v-if="termsTemplate">
      <div class="col-md-12" style="max-height: 500px; overflow-y: auto; overflow-x: hidden;">
        <copy-all-trades-confirmation/>
        <div class="row text-justify">
          <div class="alert" :class="agree ? 'alert-success' : 'alert-info'">
            <p style="font-size: 18px" v-html="txt.byfollowingterms">
            </p>
            <p style="font-size: 18px" class="text-center">
              {{ txt.pricingterms }}
<!--              <a style="color: white;-->
<!--                font-weight: bolder"-->
<!--                 href="/support/pricing"-->
<!--                 target="_blank">-->
<!--                {{ txt.linktxt }}-->
<!--              </a>-->
            </p>
          </div>
          <br>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="lead text-center">
              <p-checkbox v-model="agree" class="label-check">
                {{ txt.iagreetxt }}
              </p-checkbox>
            </p>
          </div>
        </div>
      </div>
    </template>

    <template slot="footer" v-if="termsTemplate">
       <div class="py-3 mx-auto" v-if="modalinprogress">
        <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
      </div>
      <div class="left-side" v-if="modalinprogress == false">
        <p-button type="success" @click.prevent="confirmAddAcc" link :disabled="!agree">{{ txt.addaccount }}</p-button>
      </div>
      <div class="divider" v-if="modalinprogress == false"></div>
      <div class="right-side" v-if="modalinprogress == false">
        <p-button type="default" @click.prevent="goback" link >{{ txt.back }}</p-button>
      </div>
    </template>

    <template slot="default" v-if="bulkFollowDone">
      <div style="max-height: 400px; overflow-y: auto; overflow-x: hidden;">
          <div class="row" v-for="acc in acc_list">
            <div class="col-md-6">
              <div class="row">

                <div class="col-md-12">

                  <div style="display: inline-block">
                    <el-tooltip
                      :content="txt.parameterstxt"
                      :open-delay="100"
                      placement="top"
                      popper-class="adjust-width">
                      <p-button type="primary"
                                v-on:click="goParameters(acc.id, acc.strat_id)"
                                link style=" margin-top: -15px;">

                        <i class="fa fa-gear" style="font-size: 30px;"></i>
                      </p-button>
                    </el-tooltip>
                  </div>

                  <div class="broker-logo" style="display: inline-block; float: unset; ">
                    <img
                      style="margin-top: -30px"
                      :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${acc.broker_logo}.png`">
                  </div>
                  <div style="display: inline-block;">
                    <span class="font-weight-bold" style="font-size: 20px; display: block;">
                      {{ acc.account }}
                    </span>

                    <span class="text-muted" v-if="acc.balance">
                      <b>{{ txt.balance }}:</b>
                      <b class="text-success">
                        {{ acc.balance.toFixed(2) }}
                      </b>
                    </span>
                    <span class="text-muted" v-if="acc.equity">
                      <b>{{ txt.equity }}:</b>
                      <b :class="acc.equity < acc.balance ? 'text-danger' : 'text-success'">
                        {{ acc.equity.toFixed(2) }}
                      </b>
                    </span>

                  </div>
                </div>



              </div>
            </div>
            <div class="col-md-6 alert alert-info" v-if="acc.followSuccess">
                <i class="fa fa-info-circle"></i>
                {{ $t(acc.msg) }}
            </div>
            <div class="col-md-6 alert alert-danger" v-if="!acc.followSuccess">
                <i class="fa fa-info-circle"></i>
                {{ $t(acc.msg) }}
            </div>

            <div class="col-md-6">
            </div>

          </div>
      </div>
    </template>

    <template slot="footer" v-if="bulkFollowDone" >
       <div class="py-3 mx-auto" v-if="modalinprogress">
        <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
      </div>
      <p-button type="info" @click="toMyAccounts" link v-if="!modalinprogress && isMarketplacePage">
        {{ txt.myaccounts }}
      </p-button>
      <p-button type="success" @click="resetAndClose" link v-if="!modalinprogress">
        {{ txt.done }}
      </p-button>
    </template>

    <template slot="default" v-if="noAccountsAvailable">
      <div class="row">
        <div class="col-md-12 text-center">
          <h2> {{ txt.notAvailableForStrategy }}</h2>
        </div>
      </div>
    </template>

    <template slot="footer" v-if="noAccountsAvailable">
      <p-button type="success" @click="resetAndClose" link v-if="!modalinprogress">
        {{ txt.done }}
      </p-button>
      <p-button @click="closeAndOpenAccount" link v-if="!modalinprogress">
        {{ txt.addaccount }}
      </p-button>
    </template>

  </modal>
</template>

<script>

import {mapActions, mapState} from "vuex";
import Modal from 'src/components/UIComponents/Modal';
import {Tooltip,} from 'element-ui';
import swal from "sweetalert2";
import Constants from "@/assets/constants";
import CopyAllTradesConfirmation from "@/components/Dashboard/Views/Dashboard/Accounts/CopyAllTradesConfirmation.vue";
const rules = Constants.methods.getRules()


export default {
  name: "Marketplace_modal",
  components: {CopyAllTradesConfirmation, Modal, [Tooltip.name]: Tooltip },
  computed: {
    ...mapState('marketplaceFollowStrategy', ['showAccModal','accounts', 'strategy']),
    isMarketplacePage() {
      return location.href.includes('/strategies/market') ? true : false
    }
  },
  props:{
    openAccountInfo:{
      type: Function,
      description: 'Open modal to create new account'
    }

  },
  data() {
    return {
      txt: {
        parameterstxt: this.$t('changelogpendingparamchange'),
        modalTitle: this.$t('accountstofollow'),
        byfollowingterms: this.$t('byfollowingterms'),
        pricingterms: this.$t('pricingterms'),
        addaccount: this.$t('addaccount'),
        cancel: this.$t('cancel'),
        linktxt: this.$t('linktxt'),
        iagreetxt: this.$t('iagreetxt'),
        balance: this.$t('balance'),
        notavailable: this.$t('notavailable'),
        equity: this.$t('equity'),
        inprogressMessage: this.$t('LookingForAvailableAccounts'),
        done: this.$t('done'),
        somethingwentwrong: this.$t('somethingwentwrong'),
        successFollowed: this.$t('SUCCESS_STR_FOLLOW'),
        next: this.$t('next'),
        back: this.$t('back'),
        notAvailableForStrategy: this.$t('noAccountsAvailableForStrategy'),
        myaccounts: this.$t('myaccounts')
      },
      inprogress: true,
      agree: false,
      selectAccountsTemplate: true,
      termsTemplate: false,
      showTerms: false,
      modalinprogress: false,
      selectedAccounts: [],
      acc_list: [],
      bulkFollowDone: false,
      noAccountsAvailable: false,
    }
  },
  methods: {
    ...mapActions('marketplaceFollowStrategy', ['clean']),
    ...mapState("copy_all_trades_confirmation", ["copy_all_trades"]),
    goNext() {
      this.selectAccountsTemplate = !this.selectAccountsTemplate
      this.termsTemplate = !this.termsTemplate
    },
    goback() {
      this.selectedAccounts = []
      this.selectAccountsTemplate = !this.selectAccountsTemplate
      this.termsTemplate = !this.termsTemplate
      this.termsTemplate = false
      this.inprogress = true
      this.checkStrategy()
    },
    resetAndClose() {
      this.selectAccountsTemplate = true;
      this.termsTemplate = false;
      this.bulkFollowDone = false;
      this.noAccountsAvailable = false;
      this.txt.inprogressMessage = this.$t('LookingForAvailableAccounts');
      this.clean();
    },
    toMyAccounts() {
      this.resetAndClose()
      if(rules.isAdmin) {
        this.$router.push('/admin_accounts/all');
      } else {
        this.$router.push('/accounts/main');
      }
    },
    closeAndOpenAccount() {
      this.resetAndClose()
      this.openAccountInfo()
    },
    addAccountToFollow(acc) {
      if (!this.selectedAccounts.find(el => el.id == acc.id)) {
        if(acc.balance < acc.minimum_balance) {
          swal({
            title: this.$t('attentiontxt'),
            text: this.$t('STRATEGY_LIMIT_BIGGER_ACCOUNT_BALANCE', [acc.minimum_balance]),
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
          }).then(
              value => {
                this.selectedAccounts.push(acc)
              },
              dismiss => {
                acc.selected = false
              }
          ).catch(swal.noop);
        } else {
          this.selectedAccounts.push(acc)
        }
      } else {
        this.selectedAccounts = this.selectedAccounts.filter(el => el.id  != acc.id)
      }
      this.showTerms = this.selectedAccounts.length > 0
    },
    confirmAddAcc() {
      this.modalinprogress = true
      this.inprogress = true
      this.showTerms = false
      this.txt.inprogressMessage = this.$t('bulk_folinprogress')
      this.bulkFollow(0)
    },
    updateAccList() {
      this.acc_list = this.selectedAccounts
      this.selectedAccounts = []
      this.bulkFollowDone = true
      this.selectAccountsTemplate = false
      this.termsTemplate = false
      this.inprogress = false
      this.modalinprogress = false
      this.$emit('on-finish', this.strategy)
    },
    goParameters(acc_id, strat_id) {
      window.location = "/accounts/parameters/" + acc_id + "/" + strat_id;
    },
    bulkFollow(idx) {
      if (idx < this.selectedAccounts.length) {

        const success = resp => {
          if (resp.success) {
            this.selectedAccounts[idx]['followSuccess'] = true
            this.selectedAccounts[idx]['msg'] = resp.msg
          } else {
            this.selectedAccounts[idx]['followSuccess'] = false
            this.selectedAccounts[idx]['msg'] = resp.msg
          }
          if (idx == this.selectedAccounts.length - 1) {
            this.updateAccList()
          } else {
            this.bulkFollow(idx + 1)
          }
        }

        const fail = resp => {
          this.selectedAccounts[idx]['followSuccess'] = false
          this.selectedAccounts[idx]['msg'] = resp.data.msg

          this.$toast.warning(this.$t(resp.data.msg));
          if (idx == this.selectedAccounts.length - 1) {
            this.updateAccList()
          } else {
            this.bulkFollow(idx + 1)
          }
        }
        let param = {
          copy_all_trades: this.copy_all_trades()
        }
        this.$delsertFollower(
            this.strategy,
            this.selectedAccounts[idx].id,
          false,
            param
        ).then(
          success,
          fail
        )
      }
    },
    checkStrategy() {
      let param = {
        sid: this.strategy,
        accounts: this.accounts
      }

      const success = resp => {
        if ( resp.success ) {
          this.acc_list = resp.data.filter( e => e.canFollow)
          this.inprogress = false
        }
        if (this.acc_list.length == 0) {
          fail(resp)
        }
      }

      const fail = resp => {
        console.log("fail Bulk Follow Strategy" + JSON.stringify(resp))
        this.inprogress = false
        this.selectAccountsTemplate = false
        this.noAccountsAvailable = true
      }

      if(this.accounts.length == 0){
        return fail()
      }

      this.$canBulkFollowStrategy(param).then(success, fail)
    }
  },
  watch: {
    showAccModal(val) {
      if (val) {
        this.checkStrategy()
      } else {
        this.inprogress = true
        this.agree = false
        this.modalinprogress = false
        this.selectedAccounts = []
      }
    }
  }
}
</script>

<style scoped lang="scss">
#accToFollow::v-deep {
  .form-check .form-check-sign {
    &:before, &:after {
      top: 1rem;
    }
  }
}

.broker-logo {
  float: left;
  position: relative;
  padding: 0 10px 10px 3px;
}
.broker-logo img {
  border-radius: 50%;
  width: 50px;
  border: 1px solid silver;
}
.broker-logo p-checkbox{
  padding: 10px 10px 10px 3px;
}


</style>
