<template>
  <modal  :show.sync="showDeleteMultipleAccountModal" :required="true" headerClasses="justify-content-center"
         modalClasses="modal-lg">
    <template slot="header">
      <h4 class="title title-up">{{ txt.modalTitle }}</h4>
    </template>
    <template slot="default" v-if="!allDone">
      <div id="deleteMultipleAccounts" style="max-height: 400px; overflow-y: auto; overflow-x: hidden;">
        <div class="row">
          <div class="col-md-12 text-center" v-if="inprogress" style="min-height: 220px">
            <h2> {{ txt.inprogressMessage }} </h2>
            <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
          </div>
          <div class="col-md-6" v-for="acc in accounts" v-if="!inprogress">
            <div class="broker-logo">
              <p-checkbox @input="selectAccountToDelete(acc)">
                <img
                  style="margin-top: unset;"
                  :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${acc.broker_logo}.png`">
              </p-checkbox>
            </div>
            <div>
              <span v-if="acc.account_nickname == acc.account_number || !acc.account_nickname"
                    class="font-weight-normal" style="color: black; font-size: 20px">
                  {{ acc.account_number }}
              </span>
              <span v-else class="font-weight-normal" style="color: black; font-size: 20px">
                  {{ acc.account_nickname }}  <small class="category">({{acc.account_number}})</small>
              </span>
              <br>
             <span class="text-muted" v-if="acc.info_balance">
                <b>{{ txt.balance }}:</b>
                <b class="text-success">
                  {{ acc.info_balance.toFixed(2) }}
                </b>
              </span>
              <span class="text-muted" v-if="acc.info_equity">
                <b>{{ txt.equity }}:</b>
                <b :class="acc.info_equity < acc.info_balance ? 'text-danger' : 'text-success'">
                  {{ acc.info_equity.toFixed(2) }}
                </b>
              </span>
            </div>
          </div>

        </div>
      </div>
    </template>
    <template slot="default" v-if="allDone">
      <div class="col-md-12 text-center" >
          <div class="col-md-12 text-center" style="margin-bottom: 20px">
            <i class="fa fa-thumbs-up fa-huge text-success"></i>
          </div>
          <h6> {{ $t('successtxt') }} </h6>
       </div>
    </template>
    <template slot="footer">
      <p-button type="danger" @click="validateDeleteAccounts" link v-if="!allDone">
        {{ txt.delete }}
      </p-button>
       <p-button @click="resetAndClose" link v-if="!allDone">
        {{ txt.back }}
      </p-button>
    </template>
  </modal>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Modal from 'src/components/UIComponents/Modal';
import {Tooltip,} from 'element-ui';
import swal from "sweetalert2";
import Constants from "@/assets/constants";

const rules = Constants.methods.getRules();
const user_data = JSON.parse(localStorage.getItem('user-info'));

export default {
  name: "DeleteMultipleAccounts",
  components: { Modal, [Tooltip.name]: Tooltip },
  computed: {
    ...mapState('deleteMultipleAccounts', ['showDeleteMultipleAccountModal', 'accounts'])
  },
  props:{},
  data() {
    return {
      txt: {
        balance: this.$t('balance'),
        equity: this.$t('equity'),
        delete: this.$t('deletevps'),
        back: this.$t('back'),
        done: this.$t('done'),
        modalTitle: this.$t('bulk_remaccount'),
        inprogressMessage: this.$t('bulk_remaddinprogress'),
      },
      inprogress: false,
      allAccounts: false,
      allDone: false,
      selectedAccounts: [],
      userEmail: rules.isAdmin ? this.$route.params.userid : user_data.email,
    }
  },
  methods: {
    ...mapActions('deleteMultipleAccounts', ['clean']),
    validateDeleteAccounts() {
      if ( this.selectedAccounts.length == 0) {
        this.$toast.warning(this.$t('mustSelectAccount'))
      } else {
        this.inprogress = true
        this.deleteAccounts()
      }
    },
    deleteAccounts(){
      let opSuccess = resp => {
        this.inprogress = false
        this.allDone = true
        this.$toast.success(this.$t("successtxt"));
        this.resetAndClose()
      }
      let opFail = resp => {
        this.inprogress = false
        swal(this.$t("failedoptxt"), this.$t('rdpdownmsg') , "error").then(this.resetAndClose)
      }

      let param = {
        userid: this.userEmail,
        accounts: this.selectedAccounts
      }

      this.$bulkDeleteAccounts(param).then(opSuccess, opFail)
    },
    resetAndClose() {
      this.inprogress = false
      this.selectedAccounts = []
      this.allDone = false
      this.clean()
    },
    selectAccountToDelete(acc) {
      if(this.selectedAccounts.some( a => a.id === acc.id )) {
        this.selectedAccounts = this.selectedAccounts.filter( a => a.id !== acc.id)
      } else  {
        let account = {
          "id": acc.account_id,
          "account": acc.account_number,
          "server": acc.account_server,
          "platform": acc.account_platform
        }
        this.selectedAccounts.push(account)
      }
    },
  },
  watch: {
    showDeleteMultipleAccountModal(val) {
      if (val) {

      } else {

      }
    }
  },
}
</script>

<style scoped lang="scss">
#deleteMultipleAccounts::v-deep {
  .form-check .form-check-sign {
    &:before, &:after {
      top: 1rem;
    }
  }
}
.broker-logo {
  float: left;
  position: relative;
  padding: 0 10px 10px 3px;
}
.broker-logo img {
  border-radius: 50%;
  width: 50px;
  border: 1px solid silver;
}
.broker-logo p-checkbox{
  padding: 10px 10px 10px 3px;
}
</style>
